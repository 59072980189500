const toFixedd = (num, fixed) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};

export default {
  fixed(a) {
    a = a / 1;
    if (a < 10 && a > -10) {
      return a.toFixed(8);
    } else if (a < 1000 && a > -1000) {
      return toFixedd(a, 5);
    } else if (a >= 1000 || a <= -1000) {
      return parseFloat(a).toLocaleString();
    }
  },
  fixed8(a) {
    a = a / 1;
    return parseFloat(Math.floor(a * 100000000) / 100000000)
      .toFixed(8)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  },
  fixed2(a) {
    a = a / 1;
    return parseFloat(Math.floor(a * 100000000) / 100000000)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  },
};
